const Course = {
  intelligenceForLife: "Intelligence for Life",
  deltaInverse: "Delta Inverse",
  inverseM: "Inverse M",
  criya: "Criya",
  courseStructure: "Course Structure",
  cursiveUpperText:
    "Creativity involves breaking out of expected patterns in order to look at things in a different way You can't use up creativity the more you use it, the more you have",
  programOutcomes: "Program Outcomes",
};
export default Course;
