import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import HeaderMenu from "globals/components/header/v2/HeaderMenu";
import style from "./scss/homeScreen.module.scss";
import TestimonialSlider from "globals/components/testimonial/TestimonialSlider";
import Confluence from "./Confluence";
import bannerImage from "assets/images/v2/newbannerImage.webp";
import leaf from "assets/images/v2/leafPatterNew.webp";
import { Modal } from "semantic-ui-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import dotImage from "assets/images/v2/halfDot.webp";
import TrendingProgramV2 from "globals/components/trending/v2/TrendingProgram";
import TrendingAssessmentV2 from "globals/components/trending/v2/TrendingAssessment";
import FormLayout from "../../form/FormLayout";

const HomeScreen = ({ t, home = true }) => {
  useEffect(() => {
    document.title = "Freckled ";
  }, []);
  console.log(
    `For Testing Purpose -${window.innerWidth} X ${window.innerHeight}`
  );
  const [showFormModal, handleFormModal] = useState(false);

  return (
    <>
      <div className={style.leafConatiner}>
        <LazyLoadImage alt="fort" src={leaf} />
      </div>
      <HeaderMenu />
      <div className={style.homeScreenWrapper}>
        <div className={style.bannerAndDescWrapper}>
          <picture>
            <img
              src={bannerImage}
              alt="creativeBannerImage"
              className={style.creativeBannerImage}
            />
          </picture>
          <div className={style.whiteBackground}></div>
          <div className={style.descWrapper}>
            <h3 className={style.evokeText}>{t("evokeText")}</h3>
            <p className={style.desc}>{t("homedesc")}</p>
            <button
              className={style.demoButton}
              onClick={() => handleFormModal("demo")}
            >
              {t("requestDemo")}
            </button>
          </div>
        </div>
        {home === true && (
          <>
            <TestimonialSlider />
            <TrendingProgramV2 handleFormModal={handleFormModal} />
            <div className={style.clr4}>
              <LazyLoadImage src={dotImage} />
            </div>
            <TrendingAssessmentV2 handleFormModal={handleFormModal} />
            <Confluence handleFormModal={handleFormModal} />
          </>
        )}
      </div>
      {showFormModal && (
        <>
          <Modal open={showFormModal !== false} basic size="fullscreen">
            <Modal.Content>
              <FormLayout
                category={showFormModal}
                onCloseFn={() => handleFormModal(false)}
              />
            </Modal.Content>
          </Modal>
        </>
      )}
    </>
  );
};

export default withTranslation("home")(HomeScreen);
