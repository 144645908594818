import React, { useRef, useEffect } from "react";
import { withTranslation } from "react-i18next";
import style from "./freckledPlayer.module.scss";
import AmphiVideoPlayer from "globals/components/FreckledVideoPlayer";
import frame from "assets/images/v2/videoFrame.webp";
import videoCommonBg from "assets/images/v2/videoCommonBg.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

const FreckledPlayer = (props) => {
  const {
    imageSrc,
    videoSrc,
    name,
    videoRef,
    id,
    handleVideoEnded,
    handlePlay,
    activeVideo,
    imageAlt,
    classNameForFrame,
  } = props;
  const videoPlayerRef = useRef(null);
  const isVideoActive = activeVideo === id;
  const imageClassName = isVideoActive ? style.hideImage : style.visibleImage;

  useEffect(() => {
    if (activeVideo === id) {
      const timeoutId = setTimeout(() => {
        if (videoPlayerRef.current) {
          videoPlayerRef.current.classList.add(style.isPlaying);
        }
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      if (videoPlayerRef.current) {
        videoPlayerRef.current.classList.remove(style.isPlaying);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVideo, id]);

  const renderCoverText = (text) => {
    let textList = [text];
    const words = text?.split(" ");
    if (words?.length > 1) {
      const wordsPerLine = Math.ceil(words.length / 2);

      const firstLine = words.slice(0, wordsPerLine).join(" ");
      const secondLine = words.slice(wordsPerLine).join(" ");
      textList = [firstLine, secondLine];
    }
    return (
      <div>
        {textList.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    );
  };

  const playIcon = () => (
    <>
      <svg
        width="43"
        height="43"
        viewBox="0 0 43 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.9513 41.9027C32.9702 41.9027 41.9027 32.9702 41.9027 21.9513C41.9027 10.9325 32.9702 2 21.9513 2C10.9325 2 2 10.9325 2 21.9513C2 32.9702 10.9325 41.9027 21.9513 41.9027Z"
          stroke="white"
          strokeWidth="2.18692"
          strokeMiterlimit="10"
        />
        <path
          d="M28.5997 21.9512L18.624 15.3008V28.6017L28.5997 21.9512Z"
          stroke="white"
          strokeWidth="2.18692"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );

  return (
    <>
      <div className={`${style.videoCoverFrame} ${classNameForFrame}`}>
        <div className={style.videoPlayerWrapper} ref={videoPlayerRef}>
          <AmphiVideoPlayer
            src={videoSrc}
            id={`video${id}`}
            className={style.videoContainer}
            ref={videoRef[`video${id}`]}
            autoplay={id === activeVideo}
            onEnd={() => handleVideoEnded()}
          />
        </div>
        <div className={style.frameImageWrapper}>
          <LazyLoadImage src={frame} alt="frame" />
        </div>
        <div className={`${style.videoFrame} ${imageClassName}`}>
          <div className={`${style.videoInfoWrapper}`}>
            <div className={style.coverText}>{renderCoverText(name)}</div>
            <div
              className={style.playIconWrapper}
              onClickCapture={() => handlePlay(id)}
            >
              {playIcon()} <p>play</p>
            </div>
          </div>
          <div className={style.videoFrameWrapper}>
            <div className={style.coverImage}>
              <LazyLoadImage src={imageSrc} alt={imageAlt} />
            </div>
            <LazyLoadImage
              className={style.videoCommonBg}
              src={videoCommonBg}
              height="100%"
              width="100%"
              alt="videoBg"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation("about")(FreckledPlayer);
