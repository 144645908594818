import React, { useEffect, useState } from "react";
import { Grid, Icon } from "semantic-ui-react";
import faceBookIcon from "../../assets/images/faceBookIcon.svg";
import twitterIcon from "../../assets/images/twitterIcon.svg";
import linkedInIcon from "../../assets/images/linkedInIcon.svg";
import footerLeafImage from "../../assets/images/leafPattern4.png";
import footerImage from "../../assets/images/footerImage.svg";
import shareLink from "easy-social-share-links";
import { mediaBreakpoint } from "globals/utils/globalVariables";
import { contactNumber } from "globals/utils/constants";
import style from "./footer.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router-dom";
// import leafImg from "assets/images/leafPattern10.png";
const Footer = () => {
  const location = useLocation();
  const [routeList, setRouteList] = useState(location.pathname.split("/"));

  useEffect(() => {
    updateRouteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const updateRouteList = () => {
    setRouteList(location.pathname.split("/"));
  };

  const renderDefaultFooter = () => (
    <>
      <div className={style.footerTopLayer} />
      <div className={style.footerWrapper}>
        <LazyLoadImage
          alt="footerLeafImage"
          size="small"
          className={style.footerLeafImage}
          src={footerLeafImage}
        />
        <Grid
          centered
          columns={window.innerWidth < mediaBreakpoint.mobile ? 2 : 3}
          className={style.footer}
        >
          <Grid.Column
            verticalAlign="top"
            textAlign="center"
            computer={3}
            className={style.footerImgWrapper}
          >
            <LazyLoadImage
              alt="footerImage"
              size="small"
              className={style.footerImage}
              src={footerImage}
            />
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            textAlign="center"
            computer={5}
            mobile={8}
            className={style.addressContent}
          >
            <div className={style.header}>VISIT US</div>
            <a
              target="blank"
              rel="noopener"
              href="https://maps.app.goo.gl/5x2YxTc5Uie3vUDh6"
              className={style.content}
            >
              No. 362/7, Third Floor, <br />
              16th Main, 4th T Block East, <br />
              Jayanagar, Bengaluru <br />
              Karnataka 560041
            </a>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            textAlign="center"
            computer={4}
            mobile={8}
            className={style.contactContent}
          >
            <div className={style.header}>CONTACT US</div>
            <div className={style.content}>
              <Icon name="phone" flipped="horizontally" />
              <a target="blank" rel="noopener" href={`tel:${contactNumber}`}>
                +91 9360954224
              </a>
              <br />
              <Icon name="mail" />
              <a
                target="blank"
                rel="noopener"
                href="mailto: contact@freckled.ai"
              >
                mail@freckled.ai
              </a>
            </div>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            textAlign="center"
            computer={3}
            mobile={16}
          >
            <div className={style.header}>GET IN TOUCH</div>
            <div className={style.content}>
              <div className={style.iconWrapper}>
                <a
                  target="blank"
                  rel="noopener"
                  href={shareLink("fb", "https://github.com")}
                  aria-label="Share on Facebook"
                >
                  <LazyLoadImage
                    size="mini"
                    src={faceBookIcon}
                    alt="faceBookIcon"
                  />
                </a>
                <a
                  target="blank"
                  rel="noopener"
                  href={shareLink("tw", "https://github.com")}
                  aria-label="Tweet on Twitter"
                >
                  <LazyLoadImage
                    size="mini"
                    src={twitterIcon}
                    alt="twitterIcon"
                  />
                </a>
                <a
                  target="blank"
                  rel="noopener"
                  href="https://www.linkedin.com/company/freckled/"
                  aria-label="Connect on LinkedIn"
                >
                  <LazyLoadImage
                    size="mini"
                    src={linkedInIcon}
                    alt="linkedIn"
                  />
                </a>
              </div>
              <a className={style.viewBlog} href="/blog">
                View Blog
              </a>
            </div>
          </Grid.Column>
        </Grid>
        <LazyLoadImage
          alt="footerImage"
          size="small"
          className={style.footerImageforMobile}
          src={footerImage}
        />
      </div>
    </>
  );
  const renderFooter = () => {
    if (routeList?.includes("event")) {
      return <></>;
    } else {
      return renderDefaultFooter();
    }
  };
  return <>{renderFooter()}</>;
};

export default Footer;
