import React from "react";
import "./defaultLoader.scss";

const DefaultLoader = () => {
  return (
    <>
      <div className="divLoader">
        <div className="loader">
          <span style={{ background: "#d1bac0" }}></span>
          <span></span>
          <span style={{ background: "#000" }}></span>
        </div>
      </div>
    </>
  );
};

export default DefaultLoader;
