import React, { useEffect, useState } from "react";
import style from "./scss/formLayout.module.scss";
import consultation from "assets/images/v2/form/consultation.webp";
import demo from "assets/images/v2/form/demo.webp";
import bootcamp from "assets/images/v2/form/bootcamp.webp";
import workshop from "assets/images/v2/form/workshop.webp";
import mobileFrame from "assets/images/v2/form/mobileFormFrame.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FormSection from "./FormSection";
import { mediaBreakpoint } from "globals/utils/globalVariables";

const FormLayout = (props) => {
  const { category, onCloseFn } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  const checkResponsive = () => {
    if (windowWidth < mediaBreakpoint.bigTablet) {
      return true;
    }
    return false;
  };

  const FormList = [
    {
      id: 1,
      category: "consultation",
      modalHeader: "Consultation & transformation services",
      modalImage: consultation,
      goBackUrl: "/business",
      formTypeList: ["Corporate", "Campus", "Government"],
      purpose: "Consultation & transformation services",
    },
    {
      id: 2,
      category: "demo",
      modalHeader: "For the Demo",
      modalImage: demo,
      formTypeList: ["Individual", "Corporate", "Campus", "Government"],
      purpose: "Demo for IFL",
    },
    {
      id: 3,
      category: "bootcamps",
      modalHeader: "Bootcamps",
      modalImage: bootcamp,
      formTypeList: ["Corporate", "Campus", "Government"],
      purpose: "IFL Bootcamp",
    },
    {
      id: 4,
      category: "workshop",
      modalHeader: "Workshop",
      modalImage: workshop,
      formTypeList: ["Corporate", "Campus", "Government"],
      purpose: "IFL Workshop",
    },
  ];

  const renderCloseSvg = () => (
    <>
      <svg
        width="78"
        height="78"
        viewBox="0 0 78 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="38.8887"
          cy="39.209"
          r="37.3574"
          fill="black"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M22.6875 23.0103L55.0849 55.4077"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M22.6875 55.4077L55.0849 23.0103"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    </>
  );

  const renderedFormObj = FormList.find((ele) => ele.category === category);
  const isResponsive = checkResponsive();
  return (
    <>
      <div className={style.modalContentWrapper}>
        <div>
          {isResponsive ? (
            <>
              <div className={style.responsiveFrameWrapper}>
                <LazyLoadImage className={style.frame} src={mobileFrame} />
                <div
                  className={style.closeIconSvg}
                  onClickCapture={() => onCloseFn()}
                >
                  {renderCloseSvg()}
                </div>
              </div>
            </>
          ) : (
            <>
              <LazyLoadImage width={400} src={renderedFormObj.modalImage} />
            </>
          )}
        </div>
        <div className={style.rightSection}>
          <div className={style.headerSection}>
            <div>
              <div className={style.header}>{renderedFormObj.modalHeader}</div>
              <div className={style.leaveMsg}>
                Leave your message and we’ll get back to you shortly.
              </div>
            </div>
            <div onClickCapture={() => onCloseFn()}>{renderCloseSvg()}</div>
          </div>
          <FormSection
            formObj={renderedFormObj}
            purpose={renderedFormObj?.purpose}
          />
        </div>
      </div>
    </>
  );
};
export default FormLayout;
