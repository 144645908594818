import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
// import HomeScreen from "screens/home/HomeScreen";
import HomeScreen from "screens/home/v2/HomeScreen";
import Footer from "globals/components/Footer";
import "assets/scss/main.scss";
import DefaultLoader from "globals/components/DefaultLoader";
import whatsappIcon from "assets/images/whatsappIcon.svg";
import returnToTopIcon from "assets/images/returnToTopIcon.svg";
import mailIcon from "assets/images/mailIcon.svg";
import { contactNumber, contactMail } from "globals/utils/constants";
// import FreckledHeader from "globals/components/header/FreckledHeader";
import FreckledHeader from "globals/components/header/v2/FreckledHeader";

const Course = lazy(() => import("screens/course/Course"));
const InviewLayout = lazy(() => import("screens/in-view/InviewLayout"));
const CarrerScreen = lazy(() => import("screens/career/CareerScreen"));
const Brochure = lazy(() => import("screens/brochure/Brochure"));
const CrownAssessmentLayout = lazy(() =>
  import("screens/assessment/crown/CrownAssessmentLayout")
);
// const OfferingScreen = lazy(() => import("screens/offering/OfferingScreen"));
// const AboutScreen = lazy(() => import("screens/about/AboutScreen"));
const AboutScreen = lazy(() => import("screens/about/v2/AboutScreen"));
const Ifl = lazy(() => import("screens/ifl/ifl"));
// const ProductScreen = lazy(() => import("screens/product/ProductScreen"));
const ProductScreen = lazy(() => import("screens/product/v2/ProductScreen"));
// const Blog = lazy(() => import("screens/blog/BlogScreen"));
const BlogListScreen = lazy(() => import("screens/blog/v2/BlogListScreen"));
const BlogScreen = lazy(() => import("screens/blog/v2/BlogScreen"));
const EventScreen = lazy(() => import("screens/event/EventScreen"));
const ProgramLayout = lazy(() => import("screens/program/ProgramLayout"));
const BusinessLayout = lazy(() => import("screens/business/BusinessLayout"));
const OurPrograms = lazy(() => import("screens/business/OurPrograms"));
class App extends React.Component {
  constructor(props) {
    super(props);
    this.floatingIconsRef = React.createRef();
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.floatingIconsRef.current.style.display = "flex";
    }, 1500);
  };
  handleReturnToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  render = () => {
    const routeList = window.location.href.split("/");
    const filteredRouteValue = "event";
    return (
      <>
        <FreckledHeader />
        <Suspense fallback={<DefaultLoader />}>
          <Switch>
            <Route exact path="/about">
              <AboutScreen />
            </Route>
            <Route path="/business">
              <BusinessLayout />
            </Route>
            <Route path="/ourPrograms">
              <OurPrograms />
            </Route>
            <Route exact path="/career">
              <CarrerScreen />
            </Route>
            <Route exact path="/inview">
              <InviewLayout />
            </Route>
            <Route exact path="/home">
              <HomeScreen />
            </Route>
            <Route exact path="/program/:type" component={ProgramLayout} />
            <Route exact path="/trending/program/:type" component={Ifl} />
            <Route exact path="/product">
              <ProductScreen />
            </Route>
            <Route exact path="/trending/assessment">
              <CrownAssessmentLayout />
            </Route>
            <Route exact path="/brochure">
              <Brochure />
            </Route>
            <Route path="/event" component={EventScreen} />
            <Route exact path="/course/:id" component={Course} />
            <Route exact path="/blog" component={BlogListScreen} />
            <Route exact path="/blog/:id" component={BlogScreen} />
            <Route exact path="/">
              <HomeScreen />
            </Route>
          </Switch>
          <Footer />
        </Suspense>
        {!routeList.includes(filteredRouteValue) && (
          <>
            <div ref={this.floatingIconsRef} className="floatingIcons">
              <img
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send?phone=${contactNumber}`,
                    "_self"
                  )
                }
                src={whatsappIcon}
                alt="whatsappIcon"
              />
              <img
                onClick={() => window.open(`mailto: ${contactMail}`, "_self")}
                src={mailIcon}
                alt="mailIcon"
              />
              <img
                onClick={() => window.scrollY > 0 && this.handleReturnToTop()}
                src={returnToTopIcon}
                alt="mailIcon"
              />
            </div>
          </>
        )}
      </>
    );
  };
}

export default App;
