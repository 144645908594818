const About = {
  leafImage: "Leaf Image",
  dotImage: "Dot Image",
  faceImage: "Face Image",
  about: "About",
  peopleBehind: "People Behind the Scene",
  aboutHeader: "ABOUT",
  portFolioImage: "PortFolio Image",
  freckleCultureText: "Culture at Freckled",
  peopleImage: "People Image",
  whyFreckled: " Why Freckled",
  ourTeam: "our team",
  whyFreckledDesc:
    "We are bunch of creative, self-made, free-spirited and passionate educators driven with a purpose to create better humans. And hence, we agreed to.",
};
export default About;
