import humanSkill from "assets/images/v2/trending/human.webp";
import cognitiveSkill from "assets/images/v2/trending/cognitive.webp";
import workingSkill from "assets/images/v2/trending/working.webp";
import languageSkill from "assets/images/v2/trending/language.webp";
import personality from "assets/images/v2/trending/personality.webp";

export const assessmentData = [
  {
    id: 1,
    name: "HUMAN SKILLS",
    btnName: "human",
    skillDesc:
      "Employees gifted with remarkable human skills are most valued personally and professionally.",
    skillImage: humanSkill,
    primaryContent:
      "Human skills encompass fundamental competencies that are essential for individuals to thrive in any social setting.<br /><br />Particularly in an organisation, standing out from the rest requires exceptional transformation of those skills. Such employees not only gain success easily but also prove to be an asset and help in building a positive work environment which in turn would increase the productivity of the organization multi-fold and create a secure workspace.",
    secondaryContent:
      "<br />Such remarkable individuals are the ones who are chosen to lead organizations, and people - to inspire and motivate others by setting live examples, a true power that can modify an individual or even an entire society.<br /> <br />And the great news is that, although ‘Human acumen’ is inherently present in each one of us, acquired through the course of life through valuable experiences that shape our belief system, they are still learnable.<br />",
    indivualContent:
      "Assessing yourself will provide you with an opportunity to identify your core strengths to grab that dream job that you have and work on the areas that require your attention.",
    organisationContent:
      "Assessing such skills can be a valuable tool in helping an employer identify the right members for the organisation and understand key strengths of the employees to allot responsibilities /support/ make crucial decisions accordingly.",
    firstEgTxt: "Prioritizing tasks-90%",
    secondEgTxt: "Ability to Embrace change-83%",
  },
  {
    id: 2,
    name: "COGNITIVE SKILLS",
    btnName: "cognitive",
    skillDesc:
      "Mental capabilities and limitations  can enhance or affect one’s performance in any environment.",
    skillImage: cognitiveSkill,
    primaryContent:
      "Cognitive skills refer to mental skills of individuals that are used to assess their learning abilities. They play a vital role in  a person’s overall development as they support some of the core functionalities of the brain like, thinking, reasoning, paying attention, retaining, creativity,executive functions, problem solving and so on. <br /><br />An assessment of these skills is therefore crucial as it provides an in-depth analysis of an individual, throwing more light on his/her patterns and personality, by highlighting skills that are not usually included in most CV’s.<br />",
    indivualContent:
      "Instead of listing out only scores and education qualifications, providing brief examples or achievements that demonstrate how your cognitive skills have been applied in real-world situations is an added advantage. This not only accentuates your proactiveness but also provides a formal acknowledgment of your abilities.",
    organisationContent:
      "The results of the test is an effective factor while recruiting or  predicting the individual’s job performance and efficiency which can be a reliable metric to evaluate a candidate for their actual intelligence irrespective of their work experience or a loaded resume.",
    firstEgTxt: "Keen attention: 90%",
    secondEgTxt: "Critical Thinking: 95%",
  },
  {
    id: 3,
    name: "WORKING SKILLS",
    btnName: "working",
    skillDesc:
      "Enables adaptability of an individual which is vital in today’s globalized fast changing work environment.",
    skillImage: workingSkill,
    primaryContent:
      "Every individual who is hired by an organization is expected to possess certain qualifications that are essential to help her/him succeed at his workplace, which could range from hard / technical skills to soft/ interpersonal skills. A balance of both are imperative to help an individual  adapt, exhibit excellent performance and sustain at work.<br /><br />From the days when qualifications and degrees really mattered to the current day scenario wherein individual abilities are valued more, there has been a paradigm shift of focus. Assessment of working competence based on the individual job profile can enlighten both the employer and the employee on the strengths possessed inherently, and the prowess that is needed to display one’s excellence.<br />",
    indivualContent:
      "Displaying one’s performance metrics is particularly relevant for creative or technical roles where showcasing your actual work can be powerful. Assessments are a form of ‘Positive Endorsements’ that add credibility to one’s profile, increasing the chances of standing out to potential employers.",
    organisationContent:
      "Assessing proficiency is an excellent way to identify productivity gaps and when addressed appropriately can greatly improve adaptability, performance efficiency and help in boosting confidence of an individual encouraging him/her to offer their finest.",
    firstEgTxt: "Decision making - 93%",
    secondEgTxt: "Level of Integrity - 89%",
  },
  {
    id: 4,
    name: "LANGUAGE SKILLS",
    btnName: "language",
    skillDesc:
      "Effective communication is the primary competency required to win the trust and confidence of people, to forge ahead in life and in one's workplace.",
    skillImage: languageSkill,
    primaryContent:
      "Language skills help in determining the proficiency of an individual in a given language or assessing abilities to use the language effectively in communication. The four main aspects of communication LSRW i.e. listening, speaking, reading, and writing, and certain nuances such as fluency, vocabulary, expressiveness are taken into consideration while assessing an individual’s language skills. <br /><br />Of these, listening and reading are grouped under receptive skills, which are based on interpretation and understanding while, speaking and writing are grouped under productive skills, which are based on creating or producing a language.<br />",
    indivualContent:
      "Communication is one of the most important employability skills. It is mandatory for an individual to  enable oneself to express his/her value, thoughts and views coherently to recruiters , work partners and reporting heads. Assessing these skills to identify proficiency levels and striving to excel in it is sure to unlock opportunities like never before",
    organisationContent:
      "‘Time’ is the most valuable element for an organisation. Assessing communication abilities can help identify right candidates for the organisation. Also, helping enhance it for the existing employees can effectively streamline workflows, reduce unnecessary delays, and optimize tasks, contributing to the overall efficiency of the workforce.",
    firstEgTxt: "Capacity to comprehend - 85%",
    secondEgTxt: "Thinking fluency - 91%",
  },
  {
    id: 5,
    name: "PERSONALITY",
    btnName: "personality",
    skillDesc:
      "Knowledge of personality traits helps enhance personal / professional effectiveness and efficiency by providing  insights into  habits, work styles, and approaches to problem-solving.",
    skillImage: personality,
    primaryContent:
      "Human personality refers to the unique set of characteristic traits and patterns of thought that define an individual's distinctive identity. It encompasses the consistent ways in which people tend to think, feel, and behave across various situations over time. <br /><br />Personality is a complex and multifaceted aspect of human psychology, influenced by a combination of genetic, biological, environmental, and cultural factors. Some of the key factors being,<br />",
    secondaryContent:
      "<br /><b>Temperament</b> - It involves one’s belief, attitude, observable action / reaction, emotional response, general demeanor, social interaction and perception of the world around. Also,<br />The Big Five personality traits - Dimensions such as extraversion/introversion, openness, conscientiousness, agreeableness, and neuroticism. <b>Motivation</b> - The driving forces that influence an individual's goals, desires, and aspirations, impacting their behavior and decision-making.<br /><br /><b>Self-Concept</b> - An individual's perception and understanding of oneself, including self-esteem, self-efficacy, and self-image.<br /><br /><b>Social Interactions</b> - How an individual interacts with others, including communication style, interpersonal skills, and relationship preferences.<br /><br /><b>Developmental Factors</b> - Personality evolves and changes over the lifespan, influenced by experiences, environmental factors, and major life events.",
    indivualContent:
      "Communication is one of the most important employability skills. It is mandatory for an individual to  enable oneself to express his/her value, thoughts and views coherently to recruiters , work partners and reporting heads. Assessing these skills to identify proficiency levels and striving to excel in it is sure to unlock opportunities like never before",
    organisationContent:
      "‘Time’ is the most valuable element for an organisation. Assessing communication abilities can help identify right candidates for the organisation. Also, helping enhance it for the existing employees can effectively streamline workflows, reduce unnecessary delays, and optimize tasks, contributing to the overall efficiency of the workforce.",
  },
];
