import Common from "./Common";
import About from "./About";
import Offering from "./Offering";
import Home from "./Home";
import Assessment from "./Assessment";
import Course from "./Course";

const en = {
  common: Common,
  about: About,
  offering: Offering,
  home: Home,
  assessment: Assessment,
  course: Course,
};

export default en;
