import sfl from "assets/images/v2/trending/sfl.webp";
import lfl from "assets/images/v2/trending/lfl.webp";
import cfl from "assets/images/v2/trending/cfl.webp";
import hfl from "assets/images/v2/trending/hfl.webp";

export const individualProgramObj = {
  id: "individual",
  description:
    "Your technical skills are not enough to survive the inevitable AI. Elevate your future and unleash the power of your true  potential with this AI-Driven Program.",
  programList: [
    {
      id: 1,
      name: "sfl",
      heading:
        "Get professionally ready and set yourself up for sustainable successSet yourself up for success",
      content:
        "Shed inhibitions, understand the nuances of communication and transition into an extrovert - like a performer!",
      imageSrc: sfl,
      certificationTxtColor: "#737D51",
    },
    {
      id: 2,
      name: "lfl",
      heading: "Learn skills for Versatile thinking",
      content:
        "Augment your attention to details ,  develop Linguistic proficiency and learn the core skill i.e, Versatile thinking required for prompting the AI",
      imageSrc: lfl,
      certificationTxtColor: "#AD977B",
    },
    {
      id: 3,
      name: "cfl",
      heading: "Become a higher version of yourself",
      content:
        "Formulate ideas and solve real life problems. Cultivate habits to get exceptionally creative, stand tall and become a higher version of yourself with enriched behavioural skill.",
      imageSrc: cfl,
      certificationTxtColor: "#A3838C",
    },
    {
      id: 4,
      name: "hfl",
      heading: "Think like a CXO to bag top placements",
      content:
        "Imbibe growth methodologies from eminent personalities. Develop a clear vision of the horizon, embrace vital life skills and carve out a remarkable life trajectory",
      imageSrc: hfl,
      certificationTxtColor: "#848484",
    },
  ],
};

export const campusProgramObj = {
  id: "campus",
  description:
    "Is an educational institution responsible only for academics?<br /> What about enabling a student to have an empowered life with a sustainable career in this new age of digital immersion and intimidating AI?",
  programList: [
    {
      id: 1,
      name: "sfl",
      heading: "Set them up for success in life",
      content:
        "Help your students shed intense inhibitions and become confident. Enable them to understand the roots of language, communication and become an extrovert like a performer.",
      imageSrc: sfl,
      certificationTxtColor: "#737D51",
    },
    {
      id: 2,
      name: "lfl",
      heading: "Gift cognitive fluency and professional sustenance",
      content:
        "Build linguistic proficiency by enabling ‘Versatile thinking’ - the core skill required for prompting the AI. Gift your students a strong impetus to master the English language through LSRW.",
      imageSrc: lfl,
      certificationTxtColor: "#AD977B",
    },
    {
      id: 3,
      name: "cfl",
      heading:
        "Build behaviour to forge institutional reputation and spike admissions",
      content:
        "Cultivate positive habits to prepare your students with enriched behaviour for the future. Empower them with the capacity to formulate ideas - get exceptionally creative, stand tall and become a higher version of themselves to stand apart from the crowd.",
      imageSrc: cfl,
      certificationTxtColor: "#A3838C",
    },
    {
      id: 4,
      name: "hfl",
      heading:
        "Produce matured graduates & enable students to think like a CXO to create top placements",
      content:
        "There is a method to madness that global CXO’s apply. Equip your students to apply those methods. Get them corporate ready, have them placed and sustain in top companies. Also aid them in carving out a remarkable life trajectory. ",
      imageSrc: hfl,
      certificationTxtColor: "#848484",
    },
  ],
};

export const corporateProgramObj = {
  id: "corporate",
  description:
    "Your technical skills are not enough to survive the inevitable AI. Elevate your future and unleash the power of your true  potential with this AI-Driven Program.",
  programList: [
    {
      id: 1,
      name: "sfl",
      heading: "Build strong roots to set your organization for  success..",
      content:
        "Help your employees believe in themselves, move out of their comfort zone to deliver their best with utmost diligence and a ‘never give up’ attitude.",
      imageSrc: sfl,
      certificationTxtColor: "#737D51",
    },
    {
      id: 2,
      name: "lfl",
      heading:
        "Increase productivity by 10x - Build core skills required to prompt AI",
      content:
        "Attain linguistic proficiency. Become thorough in LSRW, develop ‘Versatile thinking’ and the ability to pay attention to details. Employees then get to work more efficiently with AI to increase productivity exponentially.",
      imageSrc: lfl,
      certificationTxtColor: "#AD977B",
    },
    {
      id: 3,
      name: "cfl",
      heading:
        "Infuse positive behaviour and culture leading to employee retention.",
      content:
        "Inculcate the right habits to enhance behaviour and create a culture that fosters solution oriented thinking. Formulate ideas that are exceptionally creative, improve collaboration, leadership and become a higher version of themselves.",
      imageSrc: cfl,
      certificationTxtColor: "#A3838C",
    },
    {
      id: 4,
      name: "hfl",
      heading:
        "Align workforce with vision and mission of the organization to mitigate attrition.",
      content:
        "Create an informed workforce which possesses a clear understanding of the corporate life and expectations. By assimilating the approaches of global achievers along with life skills, employees can set individual goals. Leading to the collective growth of the organization.",
      imageSrc: hfl,
      certificationTxtColor: "#848484",
    },
  ],
};

export const governmentProgramObj = {
  id: "government",
  programList: [
    {
      id: 1,
      name: "sfl",
      heading: "",
      content: "",
      imageSrc: sfl,
      certificationTxtColor: "#737D51",
      hasContent: false,
    },
    {
      id: 2,
      name: "lfl",
      heading: "",
      content: "",
      imageSrc: lfl,
      certificationTxtColor: "#AD977B",
      hasContent: false,
    },
    {
      id: 3,
      name: "cfl",
      heading: "",
      content: "",
      imageSrc: cfl,
      certificationTxtColor: "#A3838C",
      hasContent: false,
    },
    {
      id: 4,
      name: "hfl",
      heading: "",
      content: "",
      imageSrc: hfl,
      certificationTxtColor: "#848484",
      hasContent: false,
    },
  ],
};
