import React, { lazy, useEffect, useState } from "react";
import {
  Container,
  Icon,
  Menu,
  Segment,
  TransitionablePortal,
} from "semantic-ui-react";
import activeMenuBackground from "assets/images/v2/activeMenuBackground.webp";
import menuBackground from "assets/images/v2/menuBackground.webp";
import activeMenuBackgroundMobile from "assets/images/activeMenuBackgroundMobile.svg";
import menuBackgroundMobile from "assets/images/menuBackgroundMobile.svg";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { reacturl } from "globals/utils/constants";
import { mediaBreakpoint } from "globals/utils/globalVariables";
import { LazyLoadImage } from "react-lazy-load-image-component";
import style from "./scss/headerMenu.module.scss";

const ContactUs = lazy(() => import("globals/components/ContactUs"));

const HeaderMenu = ({ t, hideMenu = false }) => {
  let history = useHistory();
  const menuList = ["home", "about", "business", "product", "blog"];
  const [activeTabItem, setActiveTabItem] = useState(
    window.location.href.split("/").pop()
  );
  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // const blogViewCount = localStorage.getItem("blogViewCount");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (window.location.href.split("/").pop().length === 0) {
      setActiveTabItem("home");
    } else {
      setActiveTabItem(window.location.href.split("/").pop());
    }
  }, [activeTabItem]);

  const renderTab = () => (
    <div className={style.tabWrapper}>
      {React.Children.toArray(
        menuList.map((menuName) => renderTabItem(menuName))
      )}
    </div>
  );

  const renderMenu = () => (
    <>
      <Menu className={style.menu} fluid vertical>
        {React.Children.toArray(
          menuList.map((menuName) => (
            <>
              <Menu.Item
                name={menuName}
                active={activeTabItem === menuName}
                onClick={() => handleTabItemClick(menuName)}
                className={`${style.menuItem} ${
                  activeTabItem === menuName ? style.aciveMenuItem : ""
                }`}
              >
                <Icon name="angle right" />
                {t(menuName)}
              </Menu.Item>
            </>
          ))
        )}
        <Menu.Item>
          <ContactUs />
        </Menu.Item>
      </Menu>
    </>
  );

  const renderTabItem = (name) => {
    return (
      <>
        <div
          onClick={() => handleTabItemClick(name)}
          onMouseDown={() => handleTabItemClick(name)}
          className={style.tabItemWrapper}
        >
          {activeTabItem === name ? (
            <>
              <LazyLoadImage
                className={style.menuBackground}
                src={
                  windowWidth <= mediaBreakpoint.mobile
                    ? menuBackgroundMobile
                    : activeMenuBackground
                }
                alt={t(name)}
              />
            </>
          ) : (
            <>
              <LazyLoadImage
                className={style.menuBackground}
                src={
                  windowWidth <= mediaBreakpoint.mobile
                    ? activeMenuBackgroundMobile
                    : menuBackground
                }
                alt={t(name)}
              />
            </>
          )}
          <span className={style.name}>{t(name)}</span>
        </div>
      </>
    );
  };

  const handleTabItemClick = (currentTabName) => {
    if (currentTabName === "login") {
      window.open(reacturl);
    } else {
      setActiveTabItem(currentTabName);
      setOpen(false);
      history.push(`/${currentTabName}`);
    }
  };

  return (
    <>
      <div className={style.header}>
        {!hideMenu && (
          <>
            <Container className={style.headerWrapper}>
              <svg
                width="1920"
                height="95"
                viewBox="0 0 1920 95"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <foreignObject x="631" y="0" width="652" height="95">
                  {renderTab()}
                </foreignObject>
              </svg>
            </Container>
          </>
        )}
        <div className={open ? style.barIconClose : style.barIconOpen}>
          <Icon
            link
            onClick={() => setOpen(!open)}
            name={open ? "x" : "bars"}
          />
        </div>
        <TransitionablePortal
          transition={{ animation: "fade left", duration: 250 }}
          onClose={() => setOpen(false)}
          open={open}
        >
          <Segment className={style.menuWrapper}>{renderMenu()}</Segment>
        </TransitionablePortal>
      </div>
    </>
  );
};

export default withTranslation("common")(HeaderMenu);
