const Common = {
  back: "Back",
  home: "Home",
  about: "About",
  product: "Product",
  offering: "Offering",
  career: "Career",
  lookDeeper: "Look Deeper",
  login: "Login",
  inView: "In-view",
  blog: "Blog",
  business: "Business",
  inviewHeading: "IN-VIEW",
  creativityForLife: "Creativity For Life",
  trendingPrograms: "Trending Programs",
  trendingProgramsDesc:
    "The four mindfully constructed programs and its key metrices, offer an enriching experience by targeting the root of Communication and Behaviour - the fundamental skills that has the ability to bring about a paradigm shift to an organization.",
};
export default Common;
