import React, { useEffect, useState } from "react";
import freckledLogo from "assets/images/v2/freckled-logo-new.webp";
import { reacturl } from "globals/utils/constants";
import { useHistory, useLocation } from "react-router-dom";
import style from "./scss/freckledHeader.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import newLogo from "assets/images/v2/freckled-logo-new.webp";
import { Button, Modal } from "semantic-ui-react";
import FormLayout from "screens/form/FormLayout";

const FreckledHeader = () => {
  const location = useLocation();
  const [routeList, setRouteList] = useState(location.pathname.split("/"));
  const [showFormModal, handleFormModal] = useState(false);

  useEffect(() => {
    updateRouteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const updateRouteList = () => {
    setRouteList(location.pathname.split("/"));
  };
  const history = useHistory();
  const renderLoginBtn = () => {
    return (
      <>
        <div className={style.signUpLoginWrapper}>
          <a href={`${reacturl}`}>
            <p>Login/Signup</p>
          </a>
        </div>
      </>
    );
  };

  const renderDefaultHeader = () => (
    <>
      <div className={style.header}>
        <picture
          onClick={() => {
            history.push("/");
          }}
        >
          <img
            src={freckledLogo}
            alt="freckledLogo"
            className={style.headerLogo}
          />
        </picture>
        {renderLoginBtn()}
      </div>
      <span className={style.bottomBorder}></span>
    </>
  );

  const renderIflProgramScreen = () => (
    <>
      <div className={style.headerSection}>
        <div className={style.logoSection}>
          <LazyLoadImage
            className={style.logo}
            src={newLogo}
            onClick={() => {
              history.push("/");
            }}
          />
          <Button
            className={style.enrollBtn}
            onClick={() => handleFormModal("demo")}
            content="Enroll Now"
          />
        </div>
      </div>
    </>
  );

  const renderHeader = () => {
    if (routeList?.includes("program")) {
      return renderIflProgramScreen();
    } else if (routeList?.includes("event")) {
      return <></>;
    } else {
      return renderDefaultHeader();
    }
  };
  return (
    <>
      {renderHeader()}
      {showFormModal && (
        <>
          <Modal open={showFormModal !== false} basic size="fullscreen">
            <Modal.Content>
              <FormLayout
                category={showFormModal}
                onCloseFn={() => handleFormModal(false)}
              />
            </Modal.Content>
          </Modal>
        </>
      )}
    </>
  );
};

export default FreckledHeader;
