const Home = {
  evokeText: "Evoke, evolve, elevate your ecosystem",
  homedesc:
    "A global AI company that builds highly scalable intelligent technology platforms for transformation of varied human ecosystems with a vision to scientifically balance & improve human consciousness quotient, from the modulations caused by digitalization and immersion.",
  testimonialsDesc: "See how Freckled transformed people’s career and journey.",
  testimonialTitle: "Testimonials",
  corporateEmployees: "Corporate Employees",
  CampusStudents: "Campus students",
  confluenceText: "Confluence",
  confluenceDesc: "A confluence of humans and technology",
  confluenceHeader:
    "Freckled’s way of Individual and organisation Transformation",
  requestDemo: "Request a demo",
  trendingProgram: "Trending Program",
  ifl: "Intelligence For Life",
  iflDesc:
    "Enhance the executive functions of your brain with our four scientifically and psychologically constructed programs and its key metrices that offer an enriching experience by targeting the root of Communication and Behaviour - the fundamental skills that have the ability to bring about a paradigm shift to an individual and  an organization.",
  getItNow: "Get it now",
  trendingAssessments: "Trending Assessments",
  crown: "CROWN",
  crownPrimaryDesc:
    "Our AI platform looks beyond categorizing personality traits to gauge results and predict behavioural patterns. It offers creative,versatile, intentional, and purposeful behavioural assessments,which are meticulously researched, and developed considering the complexities of human behaviour.",
  crownSecDesc:
    "The platform with its multiple pattern analysis, fuzzy logic and deep-learning models extracts various individual personalities and are tailored to test the behaviour required for a position while choosing it with all fairness. Our assessments deliver invaluable support to an organization’s investments, such as time, money, man power, efforts that go into candidate-screening and decision-making processes.",
};
export default Home;
