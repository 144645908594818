import React, { Component } from "react";
import videoJs from "video.js";
import "videojs-markers";
import "videojs-markers/dist/videojs.markers.css";
import "video.js/dist/video-js.css";
import "./freckledVideoPlayer.scss";

class AmphiVideoPlayer extends Component {
  constructor(props) {
    super(props);
    // generate uniq identifier
    this.playerId = props.id || `video-player-${new Date() * 1}`;
    this.player = {};
  }

  componentDidMount = () => {
    this.initPlayer(this.props);
    this.initPlayerEvents(this.props);

    // add markers
    const { markers = [] } = this.props;
    const video = videoJs(`${this.playerId}`);
    if (markers.length > 0) {
      video.markers({
        markerStyle: {
          width: "8px",
          "background-color": "red",
        },
        markerTip: {
          display: true,
          text(marker) {
            return marker.text;
          },
        },
        breakOverlay: {
          display: true,
          displayTime: 3,
          text(marker) {
            return marker.text;
          },
        },
        onMarkerReached(marker) {
          console.log(marker);
        },
        markers,
      });
    }

    videoJs.dom.createEl("div", {
      className: "vjs-marker-point",
    });

    return () => this.player.dispose();
  };

  componentDidUpdate = (prevProps) => {
    const { src, hideControls } = this.props;
    if (src !== prevProps.src) {
      this.setControlsVisibility(this.player, hideControls);

      this.initPlayer(this.props);
    }
  };

  // set visibility for controls options
  setControlsVisibility = (playerObj, hiddenControls) => {
    const Controls = {
      play: "playToggle",
      volume: "volumePanel",
      seekbar: "progressControl",
      timer: "remainingTimeDisplay",
      playbackrates: "playbackRateMenuButton",
      fullscreen: "fullscreenToggle",
    };

    // eslint-disable-next-line array-callback-return
    Object.keys(Controls).map((x) => {
      playerObj.controlBar[Controls[x]].show();
    });

    if (hiddenControls) {
      // eslint-disable-next-line array-callback-return
      hiddenControls.map((x) => {
        playerObj.controlBar[Controls[x]].hide();
      });
    }
  };

  // generate player options from props
  generatePlayerOptions = ({
    controls,
    autoplay,
    preload,
    width,
    height,
    bigPlayButton,
    hidePlaybackRates,
    fluid,
    responsive,
  }) => {
    const { playbackRates, hideControls } = this.props;
    const playerOptions = {};
    playerOptions.controls = controls;
    playerOptions.autoplay = autoplay;
    playerOptions.preload = preload;
    playerOptions.width = width;
    playerOptions.height = height;
    playerOptions.bigPlayButton = bigPlayButton;
    playerOptions.fluid = fluid;
    playerOptions.responsive = responsive;
    const hidePlayBackRates =
      hidePlaybackRates !== null || hideControls.includes("playbackrates");
    if (!hidePlayBackRates) playerOptions.playbackRates = playbackRates;
    return playerOptions;
  };

  // init Player
  initPlayer = () => {
    const { src, poster, hideControls } = this.props;
    console.log("srcsrcsrcsrc", src);
    const playerOptions = this.generatePlayerOptions(this.props);
    this.player = videoJs(
      document.querySelector(`#${this.playerId}`),
      playerOptions
    );
    this.player.src(src);
    this.player.poster(poster);
    this.setControlsVisibility(this.player, hideControls);
  };

  initPlayerEvents = ({
    onReady,
    onPlay,
    onPause,
    onTimeUpdate,
    onSeeking,
    onSeeked,
    onEnd,
  }) => {
    let currentTime = 0;
    let previousTime = 0;
    let position = 0;

    this.player.ready(() => {
      onReady(this.player);
      window.player = this.player;
    });

    this.player.on("play", () => {
      onPlay(this.player.currentTime());
    });

    this.player.on("pause", () => {
      onPause(this.player.currentTime());
    });

    // eslint-disable-next-line no-unused-vars
    this.player.on("timeupdate", (e) => {
      onTimeUpdate(this.player.currentTime());
      previousTime = currentTime;
      currentTime = this.player.currentTime();
      if (previousTime < currentTime) {
        position = previousTime;
        previousTime = currentTime;
      }
    });

    this.player.on("seeking", () => {
      this.player.off("timeupdate", () => {});
      this.player.one("seeked", () => {});
      onSeeking(this.player.currentTime());
    });

    this.player.on("seeked", () => {
      const completeTime = Math.floor(this.player.currentTime());
      onSeeked(position, completeTime);
    });

    this.player.on("ended", () => {
      onEnd();
    });
  };

  render = () => {
    const { bigPlayButtonCentered, className } = this.props;

    return (
      <video
        id={this.playerId}
        className={`video-js ${
          bigPlayButtonCentered ? "vjs-big-play-centered" : ""
        } ${className}`}
      />
    );
  };
}

AmphiVideoPlayer.defaultProps = {
  src: "",
  poster: "",
  controls: true,
  autoplay: false,
  preload: "auto",
  playbackRates: [0.5, 1, 1.5, 2],
  hidePlaybackRates: false,
  className: "",
  hideControls: [],
  bigPlayButton: true,
  bigPlayButtonCentered: true,
  fluid: true,
  responsive: true,
  onReady: () => {},
  onPlay: () => {},
  onPause: () => {},
  onTimeUpdate: () => {},
  onSeeking: () => {},
  onSeeked: () => {},
  onEnd: () => {},
};

export default AmphiVideoPlayer;
