import React, { useState } from "react";
import { Button, Form, Icon } from "semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";
import style from "./scss/formSection.module.scss";
import GlobalService from "services/GlobalService";
import { resturls } from "globals/utils/apiurls";

const FormSection = ({ formObj, purpose }) => {
  const [formType, setFormType] = useState(formObj.formTypeList[0]);
  const [userType, setUserType] = useState("Student");
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile: Yup.string().required("Mobile is required"),
    city: Yup.string().required("City is required"),
    userType: Yup.string().when("formType", {
      is: "Individual",
      then: Yup.string()
        .oneOf(["Student", "Employee"])
        .required("User type is required"),
      otherwise: Yup.string(),
    }),
    designation: Yup.string().when("formType", {
      is: () => formType !== "Individual",
      then: Yup.string().required("Designation is required"),
      otherwise: Yup.string(),
    }),
    organization: Yup.string().when("formType", {
      is: () => formType !== "Individual",
      then: Yup.string().required("Organization name is required"),
      otherwise: Yup.string(),
    }),
  });

  const formSubmit = (fields) => {
    const formData = new FormData();
    const eventName = purpose;
    const mobileNumber = `${fields.mobile}, ${fields.alternateMobile}`;
    formData.append("name", fields.name);
    formData.append("email", fields.email);
    formData.append("mobilenumber", mobileNumber);
    formData.append("location", fields.city);
    // formData.append("message", fields.message);
    formData.append("registrationPurpose", eventName);
    if (formType === "Individual") {
      formData.append("userType", userType);
    } else {
      formData.append("designation", fields.designation);
    }
    GlobalService.generalSelect(
      (respdata) => {
        if (respdata) {
          //
        }
      },
      `${resturls.registerWebsiteUser}`,
      formData,
      "POST",
      "app"
    );
  };

  return (
    <Formik
      initialValues={{
        name: "",
        designation: "",
        organization: "",
        email: "",
        mobile: "",
        alternateMobile: "",
        city: "",
        message: "",
        userType: "Student",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        formSubmit(values);
        resetForm();
      }}
    >
      {({
        handleSubmit,
        handleChange,
        touched,
        errors,
        values,
        handleBlur,
      }) => {
        return (
          <Form className={style.formWrapper}>
            <div className={style.formSection}>
              <div className={style.typeSelectSection}>
                {formObj.formTypeList.map((ele) => (
                  <div
                    key={ele}
                    onClickCapture={() => setFormType(ele)}
                    className={`${style.defaultBtn} ${
                      ele === formType ? style.activeBtn : ""
                    }`}
                  >
                    {ele}
                  </div>
                ))}
              </div>
              <Form.Group widths="equal" className={style.formField}>
                <Form.Input
                  name="name"
                  type="text"
                  placeholder="Name*"
                  value={values.name}
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={touched.name && errors.name}
                />
              </Form.Group>

              {formObj.category === "demo" && formType === "Individual" ? (
                <Form.Group
                  className={`${style.formField} ${style.redioBtnWrapper}`}
                >
                  <Form.Radio
                    label="Student"
                    name="userType"
                    value="Student"
                    checked={userType === "Student"}
                    onChange={() => setUserType("Student")}
                    className={style.radioBtn}
                  />
                  <Form.Radio
                    label="Employee"
                    name="userType"
                    value="Employee"
                    checked={userType === "Employee"}
                    onChange={() => setUserType("Employee")}
                    className={style.radioBtn}
                  />
                </Form.Group>
              ) : (
                <Form.Group widths="equal" className={style.formField}>
                  <Form.Input
                    type="text"
                    name="designation"
                    placeholder="Designation*"
                    value={values.designation}
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                    error={touched.designation && errors.designation}
                  />
                  <Form.Input
                    type="text"
                    name="organization"
                    placeholder="Organisation name*"
                    value={values.organization}
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                    error={touched.organization && errors.organization}
                  />
                </Form.Group>
              )}

              <Form.Group widths="equal" className={style.formField}>
                <Form.Input
                  type="text"
                  name="email"
                  placeholder="Email*"
                  value={values.email}
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={touched.email && errors.email}
                />
              </Form.Group>
              <Form.Group widths="equal" className={style.formField}>
                <Form.Input
                  type="number"
                  name="mobile"
                  placeholder="Mobile*"
                  value={values.mobile}
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={touched.mobile && errors.mobile}
                />
                <Form.Input
                  type="number"
                  name="alternateMobile"
                  placeholder="Alternate Mobile"
                  value={values.alternateMobile}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal" className={style.formField}>
                <Form.Input
                  type="text"
                  name="city"
                  placeholder="City*"
                  value={values.city}
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={touched.city && errors.city}
                />
              </Form.Group>
              <Form.Group widths="equal" className={style.formField}>
                <Form.Input
                  type="text"
                  name="message"
                  placeholder="Message"
                  value={values.message}
                  onChange={handleChange}
                />
              </Form.Group>
              <Button
                className={style.submitBtn}
                type="submit"
                onClick={handleSubmit}
              >
                Submit <Icon name="arrow right" />
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormSection;
