import Harshith from "assets/images/v2/TestimonialpeoplesList/HarshithGowda.webp";
import Tasha from "assets/images/v2/TestimonialpeoplesList/TashaRachelIttiavirah.webp";
import Hariesha from "assets/images/v2/TestimonialpeoplesList/Hariesha.webp";
import Tulika from "assets/images/v2/TestimonialpeoplesList/TulikaGoel.webp";
import Fenelina from "assets/images/v2/TestimonialpeoplesList/Fenelina.webp";
import Soubhik from "assets/images/v2/TestimonialpeoplesList/Soubhik.webp";
import Lankesh from "assets/images/v2/TestimonialpeoplesList/Lankesh.webp";
import Mridul from "assets/images/v2/TestimonialpeoplesList/Mridul.webp";

const EmployeesContent = [
  {
    photo: Harshith,
    name: "Harshith Gowda ",
    profession: "Theatre Artist, Mangalore",
    description:
      "“The IFL program has helped me lose my inhibitions during auditions and when performing on  stage. As a theatre artist I now understand the  various layers of human behaviour. I have gained confidence because my expressions have drastically improved. “",
    title: "Gained confidence",
  },
  {
    photo: Tasha,
    name: "Tasha Rachel Ittiavirah",
    profession: "Content developer, IOAC, Bangalore",
    title: "Practices into habits effortlessly.",
    description:
      "The course seamlessly integrated into my lifestyle and work, turning practices into habits effortlessly. The course's impact goes beyond learning, making positive changes second nature. An invaluable journey toward sustainable habits.",
  },
  {
    photo: Hariesha,
    name: "Hariesha K",
    profession: "Quality Analyst, Delloite, Bangalore",
    description:
      "The 'Day and Night' section in the HFL course gave me perspectives on the intricacies of a corporate environment. The experience enlightened me to look beyond my work, and understand the vision and mission of my company and its  impact on employee welfare.",
    title: "Looking beyond my work",
  },
  {
    photo: Tulika,
    name: "Tulika Goel",
    profession: "SEO Team Lead and Account Manager, Bangalore",
    description:
      "After IFL, I realize that  real confidence comes from being knowledgeable, self aware, and prioritizing tasks effectively. I am now able to empathize yet balance while managing my team and project my best behaviour, which is what corporates  expect in a leader.",
    title: "Real confidence",
  },
  {
    photo: Fenelina,
    name: "Fenelina Grandline DSouza",
    profession: "Curriculum developer, IOAC, Goa",
    description:
      "I've heard that butterflies spend time soaking up sunlight, to gain strength, before taking flight. I feel like the IFL program is the sunlight for my wings. It provided me with a safe and encouraging environment. Engaging in creative activities on the platform and participating in exciting games during the live sessions helped improve my communication skills and also encouraged me to take flight.",
    title: "Encouraged me to take flight",
  },
  {
    photo: Soubhik,
    name: "Soubhik Bose",
    profession: "People Manager, Mokobara, Bangalore",
    description:
      "“This programme improved my practical knowledge of conflict resolution, negotiating techniques, and 'building a brand called me' to a great extent. It gave me the special ability to succeed as a people manager and paved the way for a distinctive career path.“",
    title: "Building a brand called “me”",
  },
  {
    photo: Lankesh,
    name: "Lankesh",
    profession: "HR lytics , Bangalore",
    description:
      "Every time I heard my seniors speaking about corporate life, it felt like entering a different planet, making me apprehensive and nervous. Then, I stumbled upon this program called Intelligence for Life. It provided me with a lot of knowledge and awareness about the corporate field and life. Now that I am placed in a company, I can see how useful this program is for me on a day-to-day basis. It is small price to pay for a big, beautiful life.",
    title: "Small price to pay for big, beautiful life",
  },
  {
    photo: Mridul,
    name: "Mridul Bhardwaj",
    profession: "Lead - Customer Success, Bangalore",
    description:
      "My work requires me to intently listen and effectively communicate with my clients to strike a good balance . Practicing LSRW intensely with Freckled has helped me have constructive discussions with my clients and my team members with enhanced rapport",
    title: "Enhanced rapport with team and clients",
  },
];

const studentContent = [
  {
    profession: "First-year student at KL University, Hyderabad",
    description:
      "“Being expressive of my thoughts will make me more confident to get my work done, and will help me get more opportunities for a better career. Technical skills are not enough. Life skills are important for a better professional and personal life”. Thank you Freckled.",
    title: "Expression of thoughts",
  },
  {
    profession:
      "Chiranjeevi, Electronics and Communication engineering student at KPR College, Coimbatore",
    description:
      "“The IFL program was very insightful and I am thankful to my college for arranging it. It taught us skills like how to be empathetic and various other values and skills that are important for graduating students. These are helpful for students to know themselves and present themselves in a corporate environment. Thank you Freckled.”",
    title: "Learnt skills required for corporate environment",
  },
  {
    profession: "Third-year student at CVR College, Hyderabad",
    description:
      "“The Freckled intervention has made me realize the importance of self-awareness to survivein the industry. I now understand the importance of a healthy mindset to be able to do well in any aspect of life.”",
    title: "Realised the importance of self awareness",
  },
  {
    profession:
      "Amalazalya, Computer Science engineering student at KPR College, Coimbatore",
    description:
      "“I had the opportunity to attend the IFL workshop by Freckled organized by our college. Thereal-time examples and activities that were incorporated in the workshop were very engaging. The Mantra “Break your Mould to find your Gold” was empowering. Overall, it was very insightful to make us ready for the corporate world. Thank you Freckled.”",
    title: "Insightful corporate readiness programme",
  },
  {
    profession: "Sophia Sudarshan Engineering College, Pudukkotai",
    description:
      "“Our college has provided us with an exceptional learning environment, and one of the top-notch facilities is the IFL program from the Freckled. It has helped to improve our communication and life skills. It is a very motivating program. We have gained so much of confidence. I am glad to participate in this IFL program. Thank you.”",
    title: "Top notch program to improve communication & life skills",
  },
  {
    profession: "Third-year student at CVR College, Hyderabad",
    description:
      "“The Freckled intervention has given me an insight into how to deal with people and professionals in the corporate world. The IFL program activities have enlightened us to further develop our skills.”",
    title: "Enlightened about professionalism in corporate",
  },
  {
    profession: "First-year student at KL University, Hyderabad",
    description:
      "“I’ve never experienced this kind of program before. I would like our University to have this IFL course as part of our curriculum.”",
    title: "A never before experience",
  },
];

export { EmployeesContent, studentContent };
