export const protocol = "https";
export const domain = "apipro.freckled.ai";
export const port = "";
export const reactdomain = "pro.freckled.ai";
export const reactport = "";
export const reactcontext = "";
export const apicontext = "";
export const contactNumber = "919360954224";
export const appurl = "https://app.freckled.ai";
export const contactMail = "contact@freckled.ai";
export const eventContactNo = "+91 9611629379";
export const eventContactMail = "varun.b@freckled.ai";
export const restbaseurl = `${protocol}://${domain}:${port}/${apicontext}`;
export const reacturl = `${protocol}://${reactdomain}:${reactport}${reactcontext}`;
