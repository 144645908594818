import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import style from "./scss/trendingProgram.module.scss";
import womenFace from "assets/images/v2/trending/womenFace.webp";
import { Container, Grid, Icon } from "semantic-ui-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  campusProgramObj,
  corporateProgramObj,
  governmentProgramObj,
  individualProgramObj,
} from "./ProgramData";
import HTMLReactParser from "html-react-parser";
import { mediaBreakpoint } from "globals/utils/globalVariables";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TrendingProg = ({ t, handleFormModal }) => {
  const [activeProgram, setActiveProgram] = useState("individuals");
  const history = useHistory();
  let selectedProgramObj = {};
  switch (activeProgram) {
    case "individuals":
      selectedProgramObj = individualProgramObj;
      break;
    case "campus":
      selectedProgramObj = campusProgramObj;
      break;
    case "corporate":
      selectedProgramObj = corporateProgramObj;
      break;
    default:
      selectedProgramObj = governmentProgramObj;
      break;
  }
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  const checkResponsive = () => {
    if (windowWidth < mediaBreakpoint.bigTablet) {
      return true;
    }
    return false;
  };
  const statisticList = [
    {
      id: 1,
      content:
        "<span>Highly scalable</span> AI based auto evaluation platform ",
    },
    {
      id: 2,
      content:
        "Enriched <span>EXECUTIVE FUNCTIONS </span> of the brain through <span>CBT, NLP & Multiple Intelligence </span>",
    },
    {
      id: 3,
      content:
        "Over <span>11,000</span>, personalized  activities + 250 modules",
    },
    {
      id: 4,
      content: "<span>Online and Offline</span> interventions ",
    },
    {
      id: 5,
      content:
        "Structured and <span>flexible learning</span> + life time access",
    },
  ];
  const isResponsive = checkResponsive();
  const handleRedirect = () => {
    if (activeProgram !== "government") {
      history.push(`/program/${activeProgram}`);
    }
  };
  return (
    <>
      <div className={style.trendingProgContainer}>
        <div className={style.primaryContentWrapper}>
          <div className={style.trendingProgramTxt}>{t("trendingProgram")}</div>
          <div className={style.iflTxt}>{t("ifl")}</div>
          <div className={style.iflDesc}>{t("iflDesc")}</div>
          <div className={style.statisticWrapper}>
            {isResponsive ? (
              <>
                {statisticList?.map((value, idx) => (
                  <React.Fragment key={value.id}>
                    <div className={style.statisticPt}>
                      {HTMLReactParser(value.content)}
                    </div>
                    {idx % 2 === 0 && <div className={style.divider} />}
                  </React.Fragment>
                ))}
                <div className={style.imgWrapper}>
                  <LazyLoadImage src={womenFace} />
                </div>
              </>
            ) : (
              <>
                {statisticList?.map((value, idx) => (
                  <React.Fragment key={value.id}>
                    <div className={style.statisticPt}>
                      {HTMLReactParser(value.content)}
                    </div>
                    {idx !== statisticList?.length - 1 && (
                      <div className={style.divider} />
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
          <div className={style.womenFace}>
            <LazyLoadImage src={womenFace} />
          </div>
        </div>
        <div className={style.secondaryContentWrapper}>
          <div className={style.btnWrapper}>
            <div
              onClick={() => setActiveProgram("individuals")}
              className={`${style.defaultBtn} ${
                activeProgram === "individuals" ? style.activeBtn : ""
              }`}
            >
              Individuals
            </div>
            <div
              onClick={() => setActiveProgram("campus")}
              className={`${style.defaultBtn} ${
                activeProgram === "campus" ? style.activeBtn : ""
              }`}
            >
              Campus
            </div>
            <div
              onClick={() => setActiveProgram("corporate")}
              className={`${style.defaultBtn} ${
                activeProgram === "corporate" ? style.activeBtn : ""
              }`}
            >
              Corporate
            </div>
            <div
              onClick={() => setActiveProgram("government")}
              className={`${style.defaultBtn} ${
                activeProgram === "government" ? style.activeBtn : ""
              }`}
            >
              Government
            </div>
          </div>
          <div className={style.secondaryDesc}>
            {selectedProgramObj?.description !== undefined &&
              HTMLReactParser(selectedProgramObj?.description)}
          </div>
          <Container className={style.programContainer}>
            <Grid padded columns={isResponsive ? 1 : "equal"}>
              {selectedProgramObj?.programList?.map((program) => {
                const {
                  id,
                  heading,
                  content,
                  imageSrc,
                  hasContent = true,
                  certificationTxtColor,
                } = program;
                return (
                  <>
                    <Grid.Column className={style.programColumn}>
                      {isResponsive ? (
                        <>
                          <Grid columns={2}>
                            <Grid.Column verticalAlign="middle">
                              <LazyLoadImage src={imageSrc} />
                            </Grid.Column>
                            <Grid.Column>
                              {hasContent ? (
                                <>
                                  <div className={style.heading}>{heading}</div>
                                  <div className={style.content}>{content}</div>
                                </>
                              ) : (
                                <>
                                  <div className={style.commingSoonTxt}>
                                    Comming Soon
                                  </div>
                                </>
                              )}
                              <div
                                style={{ color: certificationTxtColor }}
                                className={style.certificationTxt}
                              >
                                Level {id} certification
                              </div>
                            </Grid.Column>
                          </Grid>
                        </>
                      ) : (
                        <>
                          {hasContent ? (
                            <>
                              <div className={style.heading}>{heading}</div>
                              <div className={style.content}>{content}</div>
                            </>
                          ) : (
                            <>
                              <div className={style.commingSoonTxt}>
                                Coming Soon
                              </div>
                            </>
                          )}
                          <div>
                            <LazyLoadImage src={imageSrc} />
                          </div>
                          <div
                            style={{ color: certificationTxtColor }}
                            className={style.certificationTxt}
                          >
                            Level {id} certification
                          </div>
                        </>
                      )}
                    </Grid.Column>
                  </>
                );
              })}
            </Grid>
            <div onClick={() => handleRedirect()} className={style.knowMoreTxt}>
              Know more ...
            </div>
          </Container>
          <div className={style.requestBtn}>
            <div
              className={style.button}
              onClick={() => handleFormModal("demo")}
            >
              {t("requestDemo")}
              <Icon name="arrow right" />
            </div>
          </div>
          <div className={style.activeProgramTxt}>{activeProgram}</div>
        </div>
      </div>
    </>
  );
};
export default withTranslation("home")(TrendingProg);
