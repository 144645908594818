import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import style from "./scss/trendingAssessment.module.scss";
import crownFace from "assets/images/v2/trending/crownFace.webp";
import crownFooter from "assets/images/v2/trending/crownFooter.webp";
import egArrow from "assets/images/v2/trending/egArrow.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import HTMLReactParser from "html-react-parser";
import { Icon, Modal } from "semantic-ui-react";
import { assessmentData } from "./AssessmentData";

const TrendingAssess = ({ t, handleFormModal }) => {
  const [showFullTxt, handleFullTxt] = useState(false);
  const [showFullSkillTxt, handleFullSkillTxt] = useState(false);
  const [activeCustomerType, handleCustomerType] = useState("individuals");
  const [activeSkillType, handleSkillType] = useState(assessmentData[0]);
  const [imgModal, openImgModal] = useState(false);
  const closeIcon = () => (
    <svg
      width="40"
      height="40"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => openImgModal(false)}
      className={style.closeIcon}
    >
      <circle
        cx="38.8887"
        cy="39.209"
        r="37.3574"
        fill="black"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M22.6875 23.0103L55.0849 55.4077"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M22.6875 55.4077L55.0849 23.0103"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
  return (
    <>
      <div className={style.trendingAssessContainer}>
        <div
          className={`${style.primaryContentWrapper} ${
            showFullTxt ? style.expanded : ""
          }`}
        >
          <LazyLoadImage src={crownFace} className={style.crownFace} />
          <div className={style.trendingAssessmentTxt}>
            {t("trendingAssessments")}
          </div>
          <div className={style.crownTxt}>{t("crown")}</div>
          <div className={style.crownDesc}>
            {t("crownPrimaryDesc")}
            {showFullTxt && (
              <>
                <div className={style.extraText}>{t("crownSecDesc")}</div>
              </>
            )}
            <div
              onClickCapture={() => handleFullTxt(!showFullTxt)}
              className={style.readMoreTxt}
            >
              read {showFullTxt ? "less" : "more"} ...
            </div>
          </div>
        </div>
        <div
          className={`${style.secondaryContentWrapper} ${
            showFullSkillTxt ? style.expandedSkillTxt : ""
          }`}
        >
          <LazyLoadImage src={crownFooter} className={style.crownFooter} />
          <div className={style.assessmentToggleSection}>
            <div
              onClickCapture={() => handleCustomerType("individuals")}
              className={`${style.defaultToggleBtn} ${
                activeCustomerType === "individuals"
                  ? style.activeToggleBtn
                  : ""
              }`}
            >
              Individuals
              {activeCustomerType === "individuals" && (
                <div className={style.activeDot} />
              )}
            </div>
            <div className={style.divider} />
            <div
              onClickCapture={() => handleCustomerType("organisations")}
              className={`${style.defaultToggleBtn} ${
                activeCustomerType === "organisations"
                  ? style.activeToggleBtn
                  : ""
              }`}
            >
              Organisations
              {activeCustomerType === "organisations" && (
                <div className={style.activeDot} />
              )}
            </div>
          </div>
          <div className={style.btnWrapper}>
            {assessmentData?.map((skill) => (
              <>
                <div
                  onClickCapture={() => handleSkillType(skill)}
                  className={`${style.defaultBtn} ${
                    activeSkillType.id === skill.id ? style.activeBtn : ""
                  }`}
                >
                  {skill.btnName}
                </div>
              </>
            ))}
          </div>
          <div className={style.secondaryDesc}>{activeSkillType.skillDesc}</div>
          <div className={style.skillWrapper}>
            <div className={style.skillImageWrapper}>
              <LazyLoadImage
                src={activeSkillType.skillImage}
                onClickCapture={() => openImgModal(true)}
              />
              <div
                onClickCapture={() => openImgModal(true)}
                className={style.zoomTxt}
              >
                click to zoom <Icon name="zoom-in" />
              </div>
            </div>
            <div
              className={`${style.skillContentWrapper} ${
                showFullSkillTxt ? style.expandedskillContent : ""
              }`}
            >
              <div className={style.heading}>{activeSkillType.name}</div>
              <div className={style.content}>
                {HTMLReactParser(activeSkillType.primaryContent)}
              </div>
              <div className={style.content}>
                {showFullSkillTxt && activeSkillType.secondaryContent && (
                  <div>{HTMLReactParser(activeSkillType.secondaryContent)}</div>
                )}
              </div>
              {activeSkillType.secondaryContent !== undefined && (
                <>
                  <div
                    onClickCapture={() => handleFullSkillTxt(!showFullSkillTxt)}
                    className={style.readMoreTxt}
                  >
                    read {showFullSkillTxt ? "less" : "more"} ...
                  </div>
                </>
              )}
              <div className={style.forTxt}>
                <b>{`For ${activeCustomerType}`}</b>
              </div>
              <div>
                {activeCustomerType === "individuals"
                  ? activeSkillType.indivualContent
                  : activeSkillType?.organisationContent}
              </div>
              {activeSkillType.firstEgTxt && (
                <>
                  <div className={style.exampleWrapper}>
                    E.g - <LazyLoadImage src={egArrow} />
                    {activeSkillType.firstEgTxt}
                  </div>
                  <div className={style.secondExampleWrapper}>
                    <LazyLoadImage src={egArrow} />
                    {activeSkillType.secondEgTxt}
                  </div>
                </>
              )}
              <div className={style.requestBtn}>
                <div
                  className={style.button}
                  onClickCapture={() => handleFormModal("demo")}
                >
                  {activeCustomerType === "individuals"
                    ? t("getItNow")
                    : t("Request a demo")}
                  <Icon name="arrow right" />
                </div>
              </div>
            </div>
          </div>
          <div className={style.activeAssessmentTxtWrapper}>
            {activeSkillType.btnName}
          </div>
        </div>
      </div>
      {imgModal && (
        <>
          <Modal open={imgModal} basic size="fullscreen">
            <div className={style.closingContainer}>{closeIcon()}</div>
            <Modal.Content image>
              <LazyLoadImage
                src={activeSkillType.skillImage}
                className={style.modalImg}
              />
            </Modal.Content>
          </Modal>
        </>
      )}
    </>
  );
};
export default withTranslation("home")(TrendingAssess);
